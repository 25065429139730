<template>
    <div class="row justify-center mt-2 px-2" style="margin-bottom: 64px">
        <div class="xs12">
            <DxValidationGroup ref="formValidation">
                <div class="row font-italic font-medium color-warning mt-1">
                    * Lưu ý: Những thông tin có dấu (*) là những thông tin bắt buộc phải
                    điền!
                </div>
                <div class="row mt-2">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinPhuongTien.BienKiemSoat"
                            label="Biển kiểm soát (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Biển kiểm soát không được bỏ trống!"
                                />
                                <DxStringLengthRule
                                    :min="8"
                                    message="Biển kiểm soát không được nhỏ hơn 8 ký tự!"
                                />
                                <DxStringLengthRule
                                    :max="20"
                                    message="Biển kiểm soát không được lớn hơn 20 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <!-- <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinPhuongTien.DVVT"
                            label="Đơn vị vận tải (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            :read-only="true"
                        ></DxTextBox>
                    </div>
                </div> -->

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinPhuongTien.LoaiXe"
                            label="Loại xe (*)"
                            :data-source="DanhSachLoaiXe"
                            display-expr="TenLoaiXe"
                            value-expr="ID_LoaiXe"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Loại xe không được bỏ trống!" />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="xs6 mr-2">
                        <DxNumberBox
                            id="nbb-soghe"
                            label="Số ghế (*)"
                            labelMode="floating"
                            format="#0"
                            :showSpinButtons="true"
                            v-model="ThongTinPhuongTien.SoGhe"
                            validationMessageMode="always"
                            styling-mode="underlined"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Số ghế không được bỏ trống!" />
                                <DxRangeRule :min="0" message="Số ghế không hợp lệ!" />
                                <DxRangeRule :max="99" message="Số ghế không hợp lệ!" />
                            </DxValidator>
                        </DxNumberBox>
                    </div>
                    <div class="xs6 ml-2">
                        <DxNumberBox
                            v-model="ThongTinPhuongTien.SoGiuong"
                            label="Số giường (*)"
                            labelMode="floating"
                            validationMessageMode="always"
                            format="#0"
                            :showSpinButtons="true"
                            styling-mode="underlined"
                            valueChangeEvent="keyup"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Số giường không được bỏ trống!"
                                />
                                <DxRangeRule :min="0" message="Số giường không hợp lệ!" />
                                <DxRangeRule
                                    :max="99"
                                    message="Số giường không hợp lệ!"
                                />
                            </DxValidator>
                        </DxNumberBox>
                    </div>
                </div>

                <div class="row title-custom align-center mt-4">
                    Thông tin hạn hiệu lực
                </div>

                <div class="row align-center mt-1">
                    <DxCheckBox
                        v-model="checkGiayBienNhan"
                        text="Sử dụng giấy biên nhận thay cho đăng ký xe"
                    />
                </div>
                <div class="row mt-2">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinPhuongTien.HanBaoHiem"
                            label="Hạn bảo hiểm xe (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạn bảo hiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinPhuongTien.HanDangKiem"
                            label="Hạn đăng kiểm xe (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạn đăng kiểm xe không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinPhuongTien.HanBienNhan"
                            label="Hạn biên nhận thế chấp (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    v-if="checkGiayBienNhan"
                                    message="Hạn biên nhận thế chấp không được bỏ trống!"
                                />
                                <DxRangeRule
                                    v-if="checkGiayBienNhan"
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    v-if="checkGiayBienNhan"
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>

                <div
                    class="row transition mt-4"
                    :style="`max-height: ${
                        openThongTinMoRong ? `1000px` : `32px`
                    }; overflow: hidden`"
                >
                    <div class="xs12">
                        <div
                            class="row align-center font-16 font-medium"
                            @click="openThongTinMoRong = !openThongTinMoRong"
                        >
                            <i
                                class="mdi mdi-menu-down-outline transition"
                                :style="`transform: rotate( ${
                                    openThongTinMoRong ? `180` : `0`
                                }deg);`"
                            ></i>
                            Thông tin mở rộng
                        </div>
                        <div class="row mt-1">
                            <div class="xs12">
                                <DxTagBox
                                    v-model:value="ThongTinPhuongTien.NhaXe"
                                    label="Nhà xe"
                                    :data-source="DanhSachNhaXeSelect"
                                    display-expr="TenNhaXe"
                                    value-expr="IdNhaXe"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                    :showDropDownButton="true"
                                    :showSelectionControls="true"
                                ></DxTagBox>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinPhuongTien.SoKhung"
                                    label="Số khung"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Số khung không được lớn hơn 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinPhuongTien.SoMay"
                                    label="Số máy"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Số máy không được lớn hơn 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinPhuongTien.SoDangKyXe"
                                    label="Số đăng ký xe"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <!-- <DxRequiredRule
													v-if="!checkGiayBienNhan"
													message="Số đăng ký xe không được bỏ trống!"
												/> -->
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Số đăng ký xe không được lớn hơn 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row align-center mt-4">Thông tin khoang chứa hàng</div>
                        <div class="row mt-1">
                            <div class="xs12">
                                <DxNumberBox
                                    v-model="ThongTinPhuongTien.ChieuDai"
                                    label="Chiều dài (m)"
                                    labelMode="floating"
                                    validationMessageMode="always"
                                    format="#0"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator>
                                        <DxRangeRule
                                            :min="0"
                                            message="Chiều dài không được nhỏ hơn 0!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Chiều dài không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxNumberBox
                                    v-model="ThongTinPhuongTien.ChieuRong"
                                    label="Chiều rộng (m)"
                                    labelMode="floating"
                                    validationMessageMode="always"
                                    format="#0"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator>
                                        <DxRangeRule
                                            :min="0"
                                            message="Chiều rộng không được nhỏ hơn 0!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Chiều rộng không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxNumberBox
                                    v-model="ThongTinPhuongTien.ChieuCao"
                                    label="Chiều cao (m)"
                                    labelMode="floating"
                                    validationMessageMode="always"
                                    format="#0"
                                    styling-mode="underlined"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator>
                                        <DxRangeRule
                                            :min="0"
                                            message="Chiều cao không được nhỏ hơn 0!"
                                        />
                                        <DxRangeRule
                                            :max="2147483647"
                                            message="Chiều cao không được vượt quá 2,147,483,647!"
                                        />
                                    </DxValidator>
                                </DxNumberBox>
                            </div>
                        </div>

                        <div class="row align-center mt-4">
                            Thông tin giám sát hành trình
                        </div>
                        <div class="row mt-1">
                            <div class="xs12">
                                <DxSelectBox
                                    v-model="ThongTinPhuongTien.HangGSHT"
                                    label="Hãng GSHT"
                                    :data-source="DanhSachHangGSHT"
                                    display-expr="TenHangGSHT"
                                    value-expr="ID_HangGSHT"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    autocomplete="off"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                    :showClearButton="true"
                                ></DxSelectBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinPhuongTien.TaiKhoan"
                                    label="Tài khoản"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Tài khoản không được lớn hơn 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-4 mb-3">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinPhuongTien.MatKhau"
                                    label="Mật khẩu"
                                    labelMode="floating"
                                    :mode="pmMatKhau"
                                    dropDownButtonTemplate="customIcon"
                                    styling-mode="underlined"
                                    autocomplete="none"
                                    ref="password"
                                    :buttons="[
                                        {
                                            options: pbMatKhau,
                                            name: 'password',
                                            location: 'after',
                                        },
                                    ]"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Mật khẩu không được lớn hơn 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>
                </div>
            </DxValidationGroup>
        </div>
    </div>

    <div class="button-bottom row justify-space-between align-center">
        <DxButton text="Hủy" type="danger" styling-mode="text" @click="$router.go(-1)" />
        <DxButton
            text="Lưu"
            type="default"
            styling-mode="contained"
            v-if="
                action == 'Add'
                    ? $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.ThemXe'),
                      )
                    : $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.SuaXe'),
                      )
            "
            @click="LuuThongTin()"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    DxTextBox,
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxValidator,
    DxValidationGroup,
    DxNumberBox,
    DxCheckBox,
    DxGallery,
    DxScrollView,
    DxTagBox,
} from "devextreme-vue";
import {
    DxRequiredRule,
    DxRangeRule,
    DxScrolling,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
export default {
    components: {
        DxScrollView,
        DxTextBox,
        DxSelectBox,
        DxRequiredRule,
        DxValidator,
        DxValidationGroup,
        DxDateBox,
        DxButton,
        DxNumberBox,
        DxRangeRule,
        DxScrolling,
        DxCheckBox,
        DxGallery,
        DxTagBox,
        DxStringLengthRule,
    },
    props: {
        action: { type: String, default: "Add" },
    },
    data() {
        return {
            checkGiayBienNhan: false,
            MaxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            MinDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            pmMatKhau: "password",
            pbMatKhau: {
                icon: "mdi mdi-eye",
                type: "normal",
                onClick: () => {
                    this.pmMatKhau = this.pmMatKhau === "text" ? "password" : "text";
                },
            },
            openThongTinMoRong: false,
            itemImageSelected: null,
            itemImageSelected2: null,
        };
    },
    computed: {
        ...mapState({
            DanhSachLoaiXe: (state) => state.ThongTinPhuongTien.DanhSachLoaiXe,
            DanhSachHangGSHT: (state) => state.ThongTinPhuongTien.DanhSachHangGSHT,
            ThongTinPhuongTien: (state) => state.ThongTinPhuongTien.ThongTinPhuongTien,
            DanhSachNhaXeSelect: (state) => state.ThongTinLaiXe.DanhSachNhaXeSelect,
        }),
    },
    watch: {
        // checkGiayBienNhan() {
        //     if (this.checkGiayBienNhan) {
        //         this.ThongTinPhuongTien.SoDangKyXe = null;
        //     } else {
        //         this.ThongTinPhuongTien.HanBienNhan = null;
        //     }
        // },
        ThongTinPhuongTien() {
            if (this.ThongTinPhuongTien.SoDangKyXe) {
                this.checkGiayBienNhan = false;
            }
            if (this.ThongTinPhuongTien.HanBienNhan) {
                this.checkGiayBienNhan = true;
            }
        },
    },
    methods: {
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.ThongBao(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        async LuuThongTin() {
            let validate = this.$refs.formValidation.instance.validate();

            if (validate.isValid) {
                this.$emit("LuuThongTin");
            } else {
                return this.$Helper.autoFocusError(validate);
            }
        },
    },
    created() {
        this.LayDanhSachNhaXe();
    },
    mounted() {
        let auth = this.$Core.Auth.TokenParsed;
        if (auth && auth.TenDoanhNghiep) {
            this.ThongTinPhuongTien.DVVT = auth.TenDoanhNghiep;
        }
    },
};
</script>

<style scoped>
.frame-box {
    width: 100%;
    padding: 16px 24px;
}
.title-custom {
    font-weight: 500;
    font-size: 16px;
}
.img-frame-container {
    height: 100%;
    box-shadow: 0 19px 36px #00000029;
}
.img-frame-content {
    height: calc(100% - 36px);
    background: #e5e5e5;
}
.textbox-chon-so-do,
>>> .textbox-chon-so-do div,
>>> .textbox-chon-so-do input {
    cursor: pointer;
}
:deep(.dx-tagbox.dx-texteditor.dx-editor-underlined::after) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
:deep(.dx-tagbox.dx-texteditor.dx-state-active::before),
:deep(.dx-tagbox.dx-texteditor.dx-state-focused::before) {
    border-bottom: 2px solid #03a9f4 !important;
    transform: scale(1) !important;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1) !important;
}
</style>
